.About-Section {
  border: none;
}
.About-Section .Header-Container {
  width: 50%;
}

.Experience-Container {
  grid-gap: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  margin: auto auto auto 150px;
  padding: 10px;
  width: 500px;
  overflow: scroll;
  overflow-x: hidden;
  transition: 0.5s;
}
.Experience-Container .Experience-Card {
  display: flex;
  border-radius: 50%;
  flex-direction: column;
  gap: 10px;
  height: 100px;
  margin: auto;
  overflow: hidden;
  text-align: center;
  transition: 0.5s;
  width: 100px;
}
.Experience-Container .Experience-Card .Card-Logo {
  height: 80%;
  width: 80%;
  margin: auto;
  transition: 0.5s;
  -o-object-fit: cover;
     object-fit: cover;
}
.Experience-Container .Experience-Card .Card-Logo:hover {
  transform: scale(1.2);
}
.Experience-Container .Experience-Card .Progress-bar-Container {
  display: flex;
  margin: auto;
  height: 100px;
  width: 100px;
}

.Experience-Container::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 1350px) {
  .About-Section {
    flex-direction: column;
  }
}
@media only screen and (max-width: 1350px) {
  .Experience-Container {
    height: 50%;
    margin-left: 150px;
  }
}
@media only screen and (max-width: 996px) {
  .About-Section {
    height: 100%;
  }
  .About-Section .Header-Container {
    width: 100%;
  }
  .Experience-Container {
    margin-left: 0px;
    width: 60%;
    grid-template-columns: repeat(3, 1fr);
  }
  .Experience-Container .Experience-Card {
    height: 100px;
    width: 100px;
  }
}
@media only screen and (max-width: 600px) {
  .About-Container {
    height: 100%;
  }
  .Experience-Container {
    margin-left: 0px;
    width: 60%;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 400px) {
  .Experience-Container {
    margin-left: 0px;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 320px) {
  .About-Section {
    padding: 10px;
    height: 100%;
  }
  .About-Section .Experience-Container {
    padding: 10px;
    width: 90%;
  }
}/*# sourceMappingURL=About.css.map */