.Mobile-Navlink-Container {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  background: #1d1d1d;
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: 22px;
  right: 60px;
  padding: 10px;
  gap: 30px;
  z-index: 100;
  transition: 0.5s;
  transform: translateX(100vw);
}
.Mobile-Navlink-Container .Mobile-Navlink-Items {
  text-align: center;
  margin: auto;
}
.Mobile-Navlink-Container .Mobile-Navlink-Items .Mobile-Navlink {
  color: white;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin: auto;
  transition: 0.5s;
}
.Mobile-Navlink-Container .Mobile-Navlink-Items .Mobile-Navlink .NavIcon {
  height: 25px;
  margin: auto;
}
.Mobile-Navlink-Container .Mobile-Navlink-Items .Mobile-Navlink:hover {
  color: #f20407;
}
.Mobile-Navlink-Container .Mobile-Navlink-Items .Mobile-Navlink:active {
  color: #f20407;
}
.Mobile-Navlink-Container .Mobile-Navlink-Items .DevLogs {
  color: #f20407;
}

.Show-Menu {
  transform: translate(0%);
}

@media only screen and (min-width: 996px) {
  .Mobile-Navlink-Container {
    transform: translateX(100vw);
  }
}
@media only screen and (max-width: 350px) {
  .Mobile-Navlink-Container {
    flex-direction: column;
    top: 75px;
    right: 10px;
  }
}/*# sourceMappingURL=MobileMenu.css.map */