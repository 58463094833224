.Project-Wrapper {
  display: flex;

  padding: 10px;
  gap: 20px;

  margin: auto;

  transform: translate(50% 50%);

  height: auto;
  width: 90%;

  .Info-Section {
    display: flex;
    flex-direction: column;

    gap: 20px;

    margin: auto;

    height: 100%;
    width: 50%;

    .Job-Title {
      color: grey;
      font-family: sans-serif;
      font-size: 18px;
      font-weight: 400;

      margin: auto;
      margin-left: 0px;
    }

    .Project-Title {
      color: white;
      font-family: sans-serif;
      font-size: 40px;
      font-weight: 400;

      margin: auto;
      margin-left: 0px;
    }

    .Tools-Container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      gap: 20px;

      margin: auto;
      margin-left: 0px;

      .Tool {
        border: 2px solid black;
        background-color: black;

        display: flex;

        padding: 5px;

        margin: auto;
        transform: 0.3s;

        .Tool-Text {
          color: #f20407;
          font-family: sans-serif;
          font-size: 15px;
          font-weight: 400;
        }
      }

      .Tool:hover {
        transform: scale(0.9);
      }
    }

    .Responsive-Project-Image {
      box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
        rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
        rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
      margin: auto;
      margin-left: 0px;
      display: flex;
      height: 300px;
      width: 100%;
      display: none;
      transition: 0.3s;
      object-fit: contain;
    }

    .Responsive-Project-Image:hover {
      transform: scale(0.9);
    }

    @media only screen and (max-width: 665px) {
      .Responsive-Project-Image {
        display: flex;
        width: 100%;
      }
    }

    .Description {
      color: white;
      font-family: sans-serif;
      font-size: 20px;
      font-weight: 400;

      text-align: left;

      display: flex;
      flex-wrap: wrap;

      margin: auto;
      margin-left: 0px;
    }

    .Code--Demo {
      display: flex;

      padding: 5px;
      gap: 20px;

      margin: auto;
      margin-left: 0px;

      width: 100%;

      .C--D {
        color: red;
        font-family: sans-serif;
        font-size: 18px;
        font-weight: 400;
      }
      .Status {
        color: #6e2389;
        font-family: sans-serif;
        font-size: 18px;
        font-weight: 400;
  
        margin: auto;
        margin-right: 0px;
      }
    }

  }

  .Display-Image-Container {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    display: flex;
    margin: auto;
    height: 600px;
    width: 50%;
    transition: 0.3s;
    .Project-Image {
      margin: auto;
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }

  @media only screen and (max-width: 1000px) {
    .Project-Wrapper {
      width: 100%;
    }
    .Responsive-Project-Image {
      display: flex;
    }
    .Display-Image-Container {
      height: 300px;
    }
  }

  @media only screen and (max-width: 665px) {
    .Info-Section {
      width: 100%;
    }
    .Display-Image-Container {
      display: none;
    }
  }

  @media only screen and (max-width: 580px) {
    .Info-Section {
      .Project-Title {
        font-size: 30px;
      }
    }

    @media only screen and (max-width: 400px) {
      .Info-Section {
        .Project-Title {
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .Project-Wrapper {
    width: 100%;
  }
}
