@import url("https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Text&family=Pattaya&display=swap");
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.Side-Bar {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  background: #1d1d1d;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  margin: auto;
  height: 100vh;
  width: 120px;
  transition: 0.5s;
  z-index: 100;
}
.Side-Bar .Logo-Container {
  border: 1px solid black;
  display: flex;
  margin: auto;
  margin-top: 0px;
  height: 150px;
  width: 100%;
}
.Side-Bar .Logo-Container .R-Link {
  margin: auto;
  transition: 0.3s;
}
.Side-Bar .Logo-Container .R-Link .R {
  color: #f20407;
  font-family: "Times New Roman", Times, serif;
  font-size: 100px;
  font-weight: 400;
  margin: auto;
}
.Side-Bar .Logo-Container .R-Link:hover {
  transform: scale(1.1);
}
.Side-Bar .Logo-Container .Side-Bar-Logo {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.Side-Bar .NavLink-Container {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 30px;
  width: 100%;
}
.Side-Bar .NavLink-Container .NavLink-item {
  display: flex;
  padding: 20px;
}
.Side-Bar .NavLink-Container .NavLink-item .NavLink {
  color: white;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 400;
  margin: auto;
  text-align: center;
  transition: 0.5s;
}
.Side-Bar .NavLink-Container .NavLink-item .DevLogs {
  color: #f20407;
}
.Side-Bar .NavLink-Container .NavLink-item .NavLink:hover {
  color: #f20407;
}
.Side-Bar .Social-Icon-Bar {
  display: flex;
  padding: 5px;
  gap: 15px;
  margin: auto;
  margin-bottom: 20px;
  height: "";
  width: 100%;
}
.Side-Bar .Social-Icon-Bar .Social-Icon-Container {
  border-radius: 50%;
  display: flex;
  padding: 2px;
  margin: auto;
}
.Side-Bar .Social-Icon-Bar .Social-Icon-Container .Social-Icon {
  margin: auto;
  height: 20px;
  width: 20px;
}

@media only screen and (max-width: 996px) {
  .Side-Bar {
    transform: translateX(-100vw);
  }
}/*# sourceMappingURL=SideBarStyles.css.map */