.Contact-Section {
    display: flex;
    gap: 50px;

    .Form-Section {
        display: flex;
        flex-direction: column;

        margin: auto;
        margin-left: 150px;

        gap: 20px;

        width: 50%;

        transition: .5s;

        .Contact-Header {
            margin-left: 0px;
        }

        .Contact-Form {
            display: flex;
            flex-direction: column;

            margin: auto;

            gap: 20px;

            width: 100%;

            .Input {
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                padding: 20px;
                width: 50%;
                border: none;
                background-color: #2b2b2b;
                outline: none;

                color: white;
                font-size: 18px;
            }

            .Input::-webkit-input-placeholder {
                font-size: 16px;
            }

            .Name--Email {
                border: none;
                display: flex;
                gap: 10px;
            }

            .Subject--Message {
                border: none;
                display: flex;
                flex-direction: column;
                gap: 20px;

                .Subject, .Message-Box {
                    width: 100%;
                }

                .Message-Box::-webkit-input-placeholder {
                    font-size: 18px;
                }
            }
        }
        
        .Button-Wrapper {
            margin-right: 0px;
        }
    }

    .Map--Board {
        position: relative;
        display: flex;
        height: 95%;
        margin: auto;
        width: 50%;

        transition: .5s;

        .Location-Pin {
            position: absolute;
            top: 25%;
            left: 52%;

            height: 30px;

            z-index: 1;

        }

        .Map {
            position: relative;
            height: 100%;
            width: 100%;
            margin: auto;
            object-fit: contain;
        }
    }
    
    @media only screen and (max-width: 996px) {
        .Form-Section {
            margin-left: 0px;
        }
    }

    
    @media only screen and (max-width: 930px) {
        .Form-Section, .Map--Board {
            margin: auto;
            width: 100%;
        }

        .Map--Board {
            height: 500px
        }
    }

    @media only screen and (max-width: 480px) {
        .Map--Board {
            height: 350px
        }
    }
}

@media only screen and (max-width: 930px) {
    .Contact-Section {
        flex-direction: column;
        height: 100%;
        .Map--Board {
            .Map {
                object-fit: cover;
            }
        }
    }
}