.Contact-Button, .View-More-Button, .Contact-Section-Button {
  width: 200px;
  align-items: center;
  background-color: #1d1d1d;
  border: 1px solid #f20407;
  box-sizing: border-box;
  color: #111;
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
  -webkit-user-select: none;
  z-index: 1;
  transition: 0.5s;
}

.Contact-Button:after, .View-More-Button:after, .Contact-Section-Button:after {
  border: 1px solid #f20407;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform 0.2s ease-out;
  width: 100%;
  z-index: -1;
}

.Contact-Button:hover:after, .View-More-Button:hover:after, .Contact-Section-Button:hover:after {
  transform: translate(0, 0);
}

.Contact-Button:active, .View-More-Button:active, .Contact-Section-Button:active {
  background-color: #ffdeda;
  outline: 0;
}

.Contact-Button:hover, .View-More-Button:hover, .Contact-Section-Button:hover {
  outline: 0;
}

.Main-Button-Text {
  color: #fff;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
}

@media (min-width: 768px) {
  .Contact-Button {
    padding: 0 40px;
  }
  .View-More-Button {
    padding: 0 40px;
  }
}
.Contact-Button-Text, .View-More-Button, .Contact-Section-Button {
  color: #fff;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.Contact-Button:hover, .View-More-Button:hover, .Contact-Section-Button:hover {
  background-color: rgba(242, 4, 7, 0.5);
}

.Contact-Section-Button {
  margin: auto;
  margin-right: 0px;
}/*# sourceMappingURL=MainButton.css.map */