.Portfolio-Section {
  display: flex;
  flex-direction: column;

  .Swiper {
    box-shadow: rgba(255, 255, 255, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    display: flex;
    width: 50%;
    margin: auto;
    margin-top: 50px;
    margin-left: 110px;
    width: 90%;
    .SwiperSlide {
      display: flex;
      margin: auto;
    }

    .swiper-button-next {
        background-image: url("../../../Assets/Icons/Next-Icon.png");
    }

    .swiper-button-prev {
        background-image: url("../../../Assets/Icons/Next-Icon.png");
        transform: rotate(-180deg);
    }

    .swiper-button-next, .swiper-button-prev {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
    }

    .swiper-button-next, .swiper-button-prev {
        color: #f20407;
    }

    .swiper-button-next::after, .swiper-button-prev::after {
        display: none;
    }
  }

  .Portfolio-Container {
    display: flex;

    padding: 10px;

    position: relative;

    margin: auto;
    margin-left: 100px;

    // overflow: scroll;
    // overflow-x: hidden;

    width: 80%;

    transition: 0.5s;
  }

  @media only screen and (max-width: 996px) {
    .Swiper {
      margin-left: 0px;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 996px) {
  .Portfolio-Section {
    gap: 20px;
    height: 100%;
  }
}
