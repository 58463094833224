@import url("https://fonts.googleapis.com/css2?family=Amiri&family=Libre+Barcode+39+Text&family=Pattaya&display=swap");
.Page-Section {
  background-color: #1d1d1d;
  display: flex;
  padding: 20px;
  position: relative;
  margin: auto;
  height: 100vh;
  width: 100%;
}
.Page-Section .Header-Container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
  margin: auto;
  margin-left: 150px;
  transition: 0.5s;
}
.Page-Section .Header-Container .Header-Text {
  color: white;
  font-family: sans-serif;
  font-size: 103px;
  font-weight: 400;
  text-align: left;
  margin: auto;
  margin-left: 0px;
  transition: 0.5s;
}
.Page-Section .Header-Container .Header-Text .Red-Letter {
  color: red;
}
.Page-Section .Header-Container .Job-Title {
  color: white;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
}
.Page-Section .Header-Container .Job-Title .Red-Letter {
  color: red;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}
.Page-Section .Header-Container .Section-Description {
  color: white;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  margin-left: 0px;
  transition: 0.5s;
  width: 100%;
}
.Page-Section .Header-Container .Section-Description .Red-Letter {
  color: red;
}
.Page-Section .Header-Container .Social-Icon-Container {
  display: flex;
  margin-left: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  gap: 20px;
}
.Page-Section .Header-Container .Social-Icon-Container .SocialIcon {
  margin: auto;
  height: 35px;
}
@media only screen and (max-width: 1300px) {
  .Page-Section .Header-Container .Header-Text {
    font-size: 83px;
  }
}
@media only screen and (max-width: 996px) {
  .Page-Section .Header-Container {
    margin-left: 0px;
  }
  .Page-Section .Header-Container .Header-Text {
    font-size: 63px;
  }
}
@media only screen and (max-width: 500px) {
  .Page-Section .Header-Container {
    margin-left: 0px;
  }
  .Page-Section .Header-Container .Header-Text {
    font-size: 53px;
  }
  .Page-Section .Header-Container .Section-Description {
    font-size: 18px;
  }
}
@media only screen and (max-width: 360px) {
  .Page-Section .Header-Container {
    margin-left: 0px;
  }
  .Page-Section .Header-Container .Header-Text {
    font-size: 53px;
  }
  .Page-Section .Header-Container .Section-Description {
    font-size: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .Page-Section {
    padding: 10px;
  }
}/*# sourceMappingURL=GlobalPageStyles.css.map */