.Contact-Section {
  display: flex;
  gap: 50px;
}
.Contact-Section .Form-Section {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-left: 150px;
  gap: 20px;
  width: 50%;
  transition: 0.5s;
}
.Contact-Section .Form-Section .Contact-Header {
  margin-left: 0px;
}
.Contact-Section .Form-Section .Contact-Form {
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 20px;
  width: 100%;
}
.Contact-Section .Form-Section .Contact-Form .Input {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 20px;
  width: 50%;
  border: none;
  background-color: #2b2b2b;
  outline: none;
  color: white;
  font-size: 18px;
}
.Contact-Section .Form-Section .Contact-Form .Input::-webkit-input-placeholder {
  font-size: 16px;
}
.Contact-Section .Form-Section .Contact-Form .Name--Email {
  border: none;
  display: flex;
  gap: 10px;
}
.Contact-Section .Form-Section .Contact-Form .Subject--Message {
  border: none;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Contact-Section .Form-Section .Contact-Form .Subject--Message .Subject, .Contact-Section .Form-Section .Contact-Form .Subject--Message .Message-Box {
  width: 100%;
}
.Contact-Section .Form-Section .Contact-Form .Subject--Message .Message-Box::-webkit-input-placeholder {
  font-size: 18px;
}
.Contact-Section .Form-Section .Button-Wrapper {
  margin-right: 0px;
}
.Contact-Section .Map--Board {
  position: relative;
  display: flex;
  height: 95%;
  margin: auto;
  width: 50%;
  transition: 0.5s;
}
.Contact-Section .Map--Board .Location-Pin {
  position: absolute;
  top: 25%;
  left: 52%;
  height: 30px;
  z-index: 1;
}
.Contact-Section .Map--Board .Map {
  position: relative;
  height: 100%;
  width: 100%;
  margin: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
@media only screen and (max-width: 996px) {
  .Contact-Section .Form-Section {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 930px) {
  .Contact-Section .Form-Section, .Contact-Section .Map--Board {
    margin: auto;
    width: 100%;
  }
  .Contact-Section .Map--Board {
    height: 500px;
  }
}
@media only screen and (max-width: 480px) {
  .Contact-Section .Map--Board {
    height: 350px;
  }
}

@media only screen and (max-width: 930px) {
  .Contact-Section {
    flex-direction: column;
    height: 100%;
  }
  .Contact-Section .Map--Board .Map {
    -o-object-fit: cover;
       object-fit: cover;
  }
}/*# sourceMappingURL=Contact.css.map */