.Portfolio-Section {
  display: flex;
  flex-direction: column;
}
.Portfolio-Section .Swiper {
  box-shadow: rgba(255, 255, 255, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  display: flex;
  width: 50%;
  margin: auto;
  margin-top: 50px;
  margin-left: 110px;
  width: 90%;
}
.Portfolio-Section .Swiper .SwiperSlide {
  display: flex;
  margin: auto;
}
.Portfolio-Section .Swiper .swiper-button-next {
  background-image: url("../../../Assets/Icons/Next-Icon.png");
}
.Portfolio-Section .Swiper .swiper-button-prev {
  background-image: url("../../../Assets/Icons/Next-Icon.png");
  transform: rotate(-180deg);
}
.Portfolio-Section .Swiper .swiper-button-next, .Portfolio-Section .Swiper .swiper-button-prev {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.Portfolio-Section .Swiper .swiper-button-next, .Portfolio-Section .Swiper .swiper-button-prev {
  color: #f20407;
}
.Portfolio-Section .Swiper .swiper-button-next::after, .Portfolio-Section .Swiper .swiper-button-prev::after {
  display: none;
}
.Portfolio-Section .Portfolio-Container {
  display: flex;
  padding: 10px;
  position: relative;
  margin: auto;
  margin-left: 100px;
  width: 80%;
  transition: 0.5s;
}
@media only screen and (max-width: 996px) {
  .Portfolio-Section .Swiper {
    margin-left: 0px;
    width: 100%;
  }
}

@media only screen and (max-width: 996px) {
  .Portfolio-Section {
    gap: 20px;
    height: 100%;
  }
}/*# sourceMappingURL=Portfolio.css.map */