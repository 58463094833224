.Project-Wrapper {
  display: flex;
  padding: 10px;
  gap: 20px;
  margin: auto;
  transform: translate(50% 50%);
  height: auto;
  width: 90%;
}
.Project-Wrapper .Info-Section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: auto;
  height: 100%;
  width: 50%;
}
.Project-Wrapper .Info-Section .Job-Title {
  color: grey;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin: auto;
  margin-left: 0px;
}
.Project-Wrapper .Info-Section .Project-Title {
  color: white;
  font-family: sans-serif;
  font-size: 40px;
  font-weight: 400;
  margin: auto;
  margin-left: 0px;
}
.Project-Wrapper .Info-Section .Tools-Container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin: auto;
  margin-left: 0px;
}
.Project-Wrapper .Info-Section .Tools-Container .Tool {
  border: 2px solid black;
  background-color: black;
  display: flex;
  padding: 5px;
  margin: auto;
  transform: 0.3s;
}
.Project-Wrapper .Info-Section .Tools-Container .Tool .Tool-Text {
  color: #f20407;
  font-family: sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.Project-Wrapper .Info-Section .Tools-Container .Tool:hover {
  transform: scale(0.9);
}
.Project-Wrapper .Info-Section .Responsive-Project-Image {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  margin: auto;
  margin-left: 0px;
  display: flex;
  height: 300px;
  width: 100%;
  display: none;
  transition: 0.3s;
  -o-object-fit: contain;
     object-fit: contain;
}
.Project-Wrapper .Info-Section .Responsive-Project-Image:hover {
  transform: scale(0.9);
}
@media only screen and (max-width: 665px) {
  .Project-Wrapper .Info-Section .Responsive-Project-Image {
    display: flex;
    width: 100%;
  }
}
.Project-Wrapper .Info-Section .Description {
  color: white;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  margin-left: 0px;
}
.Project-Wrapper .Info-Section .Code--Demo {
  display: flex;
  padding: 5px;
  gap: 20px;
  margin: auto;
  margin-left: 0px;
  width: 100%;
}
.Project-Wrapper .Info-Section .Code--Demo .C--D {
  color: red;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.Project-Wrapper .Info-Section .Code--Demo .Status {
  color: #6e2389;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin: auto;
  margin-right: 0px;
}
.Project-Wrapper .Display-Image-Container {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  display: flex;
  margin: auto;
  height: 600px;
  width: 50%;
  transition: 0.3s;
}
.Project-Wrapper .Display-Image-Container .Project-Image {
  margin: auto;
  -o-object-fit: contain;
     object-fit: contain;
  height: 100%;
  width: 100%;
}
@media only screen and (max-width: 1000px) {
  .Project-Wrapper .Project-Wrapper {
    width: 100%;
  }
  .Project-Wrapper .Responsive-Project-Image {
    display: flex;
  }
  .Project-Wrapper .Display-Image-Container {
    height: 300px;
  }
}
@media only screen and (max-width: 665px) {
  .Project-Wrapper .Info-Section {
    width: 100%;
  }
  .Project-Wrapper .Display-Image-Container {
    display: none;
  }
}
@media only screen and (max-width: 580px) {
  .Project-Wrapper .Info-Section .Project-Title {
    font-size: 30px;
  }
}
@media only screen and (max-width: 580px) and (max-width: 400px) {
  .Project-Wrapper .Info-Section .Project-Title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .Project-Wrapper {
    width: 100%;
  }
}/*# sourceMappingURL=Project.css.map */