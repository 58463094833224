* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.App {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;    
  background-color: #1d1d1d;
}

/* width */
::-webkit-scrollbar {
  border: none;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #1d1d1d;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f20407;
  border-radius: 10px;
}


.Mobile-Menu-Icon {
  position: fixed;
  top: 20px;
  right: 10px;

  color: white;


  height: 50px;
  z-index: 101;

  transition: .5s;
  transform: translateX(100vw);
}

@media only screen and (max-width: 996px) {
  .Mobile-Menu-Icon {
    transform: translateX(0%);
  }
}


.Back-To-Top {
  position: fixed;
  bottom: 20px;
  right: 20px;

  height: 40px;

  z-index: 99;

  transition: 1s;

  transform: rotate(90deg) translateX(100vh);
}

.Show-back-To-Top-Button {
  transform: rotate(-90deg)  translateX(0%);
}
