@import url('https://fonts.googleapis.com/css2?family=Amiri&family=Libre+Barcode+39+Text&family=Pattaya&display=swap');

.Page-Section {
    background-color: #1d1d1d;

    display: flex;
    // flex-direction: column;

    padding: 20px;
    // gap: 100px;

    position: relative;
    margin: auto;

    height: 100vh;
    width: 100%;

    .Header-Container {
        display: flex;
        flex-direction: column;

        padding: 10px;
        gap: 20px;

        margin: auto;
        margin-left: 150px;

        transition: .5s;

        .Header-Text {
            color: white;
            font-family: sans-serif;
            font-size: 103px;
            font-weight: 400;
            text-align: left;

            margin: auto;
            margin-left: 0px;
            transition: .5s;

            .Red-Letter {
                color: red;
            }
        }

        .Job-Title {
            color: white;
            font-family: sans-serif;
            font-size: 20px;
            font-weight: 400;
            text-align: left;

            display: flex;
            flex-wrap: wrap;
            
            .Red-Letter {
                color: red;
                font-family: sans-serif;
                font-size: 20px;
                font-weight: 400;
                text-align: left;
    
            }
        }

        .Section-Description {
            color: white;
            font-family: sans-serif;
            font-size: 20px;
            font-weight: 400;
            text-align: left;

            display: flex;
            flex-wrap: wrap;

            margin: auto;
            margin-left: 0px;
            transition: .5s;

            width: 100%;

            
            .Red-Letter {
                color: red;
            }
        }

        .Social-Icon-Container {
            display: flex;
            margin-left: 0px;
            width: fit-content;
            gap: 20px;

            .SocialIcon {
                margin: auto;
                height: 35px;
            }
        }
    }

    @media only screen and (max-width: 1300px) {
        .Header-Container {         
            .Header-Text {
                font-size: 83px;
            }
        }
    }

    @media only screen and (max-width: 996px) {
        .Header-Container {
            margin-left: 0px;
            
            .Header-Text {
                font-size: 63px;
            }
            
        }
    }

    @media only screen and (max-width: 500px) {
        .Header-Container {
            margin-left: 0px;
            
            .Header-Text {
                font-size: 53px;
            }

            .Section-Description {
                font-size: 18px;
            } 
           
        }
    }

    @media only screen and (max-width: 360px) {
        .Header-Container {
            margin-left: 0px;
            
            .Header-Text {
                font-size: 53px;
            }

            .Section-Description {
                font-size: 15px;
            } 
           
        }
    }

}


@media only screen and (max-width: 500px) {
    .Page-Section {
        padding: 10px;
    }
}